.custom-img {
  object-fit: cover;
  border-radius: 50%;
  max-width: 100%;
}

/* Styles for large screens (>= 992px) */
@media (min-width: 992px) {
  .custom-img {
    width: 400px;
    height: 400px;
  }
}

/* Styles for mobile screens (<= 768px) */
@media (max-width: 768px) {
  .custom-img {
    width: 250px;
    height: 250px;
  }
}
