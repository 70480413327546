.certificate-img {
  max-width: 100%;
  overflow: "hidden";
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for large screens (>= 992px) */
@media (min-width: 992px) {
  .certificate-img {
    height: 300px;
  }
}

/* Styles for mobile screens (<= 768px) */
@media (max-width: 768px) {
  .certificate-img {
    height: auto;
  }
}
